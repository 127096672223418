import React, { useState } from "react";
import { createRoot } from "react-dom/client";
import Calendar from "react-datepicker";
import moment from 'moment';

import "react-datepicker/dist/react-datepicker.css";

let Editor = {};

function CalendarElement() {
  let [calendarValue, setCalendarValue] = useState();

  Editor.createCell = function (cell, value, x, y, instance, options) {
    cell.innerHTML = value;
  };

  Editor.updateCell = function (cell, value, x, y, instance, options) {
    if (cell) {
      cell.innerHTML = moment(value).format('MM/DD/yyyy hh:mm A');
    }
  };

  Editor.openEditor = function (cell, value, x, y, instance, options) {
    // Append the DOM to my cell
    instance.parent.input.appendChild(rootElement);
    // Make sure input container is not editable
    instance.parent.input.setAttribute("contentEditable", false);
    // Render React element to my blank DOM
    if (value) {
      value = new Date(value);
    }
    // Define current date
    setCalendarValue(value);
  };

  Editor.closeEditor = function (cell, save, x, y, instance, options) {
    // Remote DOM to my cell
    rootElement.remove();
    if (save) {
      cell.innerText = calendarValue;
      return calendarValue;
    }
  };

  let change = function (e) {
    // Set the new date
    setCalendarValue(e);

  };

  return (
    <>
      <Calendar inline showTimeSelect onChange={(e) => change(e)} selected={calendarValue} />
    </>
  );
}

const rootElement = document.createElement("root");
const root = createRoot(rootElement);
root.render(<CalendarElement />);

export default Editor;
