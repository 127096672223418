import Spreadsheet from './js-spreadsheet/spreadsheet';
import './App.css';
import worklogData from './data/worklog';

function App() {
  return (
    <div className="App">
      <Spreadsheet worksheet = {worklogData.Worklog}></Spreadsheet>
    </div>
  );
}

export default App;
