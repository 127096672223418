import Calendar from "../Editors/Calander";

export default {
    Worklog : [{
        data: [
            [ "11/28/22", "", "Lead", 7.5, 'Koon;Denzel', 1.00, 7.50, '11/28/22,8:14 AM', '11/28/22,8:15 AM', '', '12 days ago' ],
            [ "11/28/22", "NTW 53", "Inbound", 7.5, 'Koon;Denzel', 1.00, 0.00, '11/28/22,8:15 AM', '11/28/22,8:16 AM', '', '12 days ago' ],
            [ "11/28/22", "", "Lead", 7.5, 'Cromer;Brant', 1.00, 7.50, '11/28/22,8:12 AM', '11/28/22,8:14 AM', '', '12 days ago' ],
            [ "11/28/22", "Door 42", "Outbound", 7.5, 'Cromer;Brant', 0.50, 0.00, '11/28/22,8:11 AM', '11/28/22,8:14 AM', '', '12 days ago' ],
            [ "11/28/22", "", "Lead", 7.5, 'Hancock;Charles', 1.00, 8.00, '11/28/22,8:14 AM', '11/28/22,8:16 AM', '', '12 days ago' ],
            [ "11/28/22", "Door 42", "Outbound", 7.5, 'Hancock;Charles', 0.50, 0.00, '11/28/22,8:15 AM', '11/28/22,8:17 AM', '', '12 days ago' ],
            [ "11/28/22", "", "Training", 7, 'Perkins;Glenn', 1.00, 7.00, '11/28/22,8:17 AM', '11/28/22,8:18 AM', '', '12 days ago' ],
            [ "11/28/22", "", "Training", 7, 'Maybush;Melissa', 1.00, 7.00, '11/28/22,8:18 AM', '11/28/22,8:19 AM', '', '12 days ago' ],
            [ "11/28/22", "", "Training", 4.5, 'Jones;Dustin', 1.00, 4.50, '11/28/22,8:13 AM', '11/28/22,8:15 AM', '', '12 days ago' ],
            [ "11/28/22", "", "General Labor", 4.5, 'Geesaman;David', 1.00, 4.50, '11/28/22,8:16 AM', '11/28/22,8:18 AM', '', '12 days ago' ],
            [ "11/28/22", "", "General Labor", 6, 'Mrljak;Ashley', 1.00, 4.00, '11/28/22,8:17 AM', '11/28/22,8:19 AM', '', '12 days ago' ],
            [ "11/28/22", "", "General Labor", 7, 'Pineda;Amanda', 1.00, 6.00, '11/28/22,8:18 AM', '11/28/22,8:19 AM', '', '12 days ago' ],
            [ "11/28/22", "", "General Labor", 4, 'Koon;Denzel', 1.00, 7.00, '11/28/22,8:19 AM', '11/28/22,8:20 AM', '', '12 days ago' ],
            [ "11/28/22", "", "General Labor", 6, 'Oppel;Krysta', 1.00, 4.00, '11/28/22,8:12 AM', '11/28/22,8:18 AM', '', '12 days ago' ],
            [ "11/28/22", "", "Operator", 2, 'Starr;Brian', 1.00, 2.00, '11/28/22,8:12 AM', '11/28/22,8:17 AM', '', '12 days ago' ],
        ],
        columns: [
            { title:'Date', width:'200px', type: Calendar, tooltip:'Date' },
            // { title:'Date', width:'200px', type: 'calander', tooltip:'Date', options:{ format:'DD/MM/YYYY' } },
            { title:'PO#', width:'80px', tooltip:'PO#' },
            { title:'Labor Type', width:'150px',  type:'dropdown', 
            source:[
                {id:'1', name:'Lead', image:'https://www.shutterstock.com/image-illustration/leader-his-team-3d-rendered-260nw-66495871.jpg', title:'Lead', group:'Group 1'},
                {id:'2', name:'Inbound', image:'https://c8.alamy.com/comp/2E4AAP6/inbound-logistics-concept-icon-2E4AAP6.jpg', title:'Inbound', group:'Group 2'},
                {id:'3', name:'Outbound', image:'https://www.clickpost.ai/hubfs/featured%20images/Updated%20blog%20banner%20images%20Mar%2022/Outbound-Logistics.webp', title:'Outbound', group:'Group 2'},
                {id:'4', name:'Training', image:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMM87WqjWYb-Ab97PQAEFbJ6pI_hhbY-U3uA&usqp=CAU', title:'Training', group:'Group 1'},
                {id:'5', name:'General Labor', image:'https://static3.bigstockphoto.com/8/8/2/large1500/288941338.jpg', title:'General Labor', group:'Group 1'},
                {id:'6', name:'Operator', image:'https://previews.123rf.com/images/juliatim/juliatim1502/juliatim150200069/36761197-female-call-centre-operator-with-headset-flat-moderm-style.jpg', title:'Operator', group:'Group 1'}], options:{ newOptions:true,autocomplete:true },
                tooltip:'Labor Type'},
            { title:'Total', width:'100px', tooltip:'Total' },
            { title:'Employee', width:'200px', tooltip:'Employee', type:'dropdown',
            source:['Starr','Brian','Oppel', 'Krysta','Koon','Denzel','Pineda','Amanda','Mrljak','Ashley','Geesaman','David','Jones','Maybush','Melissa','Perkins','Glenn','Hancock','Charles','Cromer','Brant'],
            options:{ newOptions:true,autocomplete:true, multiple:true } },
            { title:'%', width:'50px', tooltip:'%' },
            { title:'#ACA Hours', width:'150px', tooltip:'#ACA Hours' },
            { title:'Start', width:'200px', type: Calendar, tooltip:'Start' },
            { title:'End', width:'200px', type: Calendar, tooltip:'End' },
            { title:'Note', width:'300px', tooltip:'Note' },
            { title:'Last Updated', width:'150px', type: Calendar, tooltip:'Last Updated' }
        ],
        filters: true,
        worksheetName:"Work Log"
    },
    {
        data: [
            ["tday@pfplogistics.com;Carlisleleads@pfplogistics.com", "12/5/22", 10, 7, null, 1, 10, 11, 'forkilift safety', null, null, '1 NTW inbound'],
            ["Icleveland@pfplogistics.com", "12/6/22", 10, 7, null, 0, 10, 11, 'Stretching', null, null, null],
            ["tday@pfplogistics.com;Carlisleleads@pfplogistics.com", "12/7/22", 12, 9, null, 0, 18, 11, 'Stretching', null, null, "2 NTW's"],
            ["tday@pfplogistics.com;Carlisleleads@pfplogistics.com", "12/7/22", null, null, null, null, null, 11, null, null, null, null],
            ["tday@pfplogistics.com;Carlisleleads@pfplogistics.com", "12/12/22", 10, 7, null, 0, 18, 14, 'Forklifts', null, null, 'I NTW Truck'],
            ["tday@pfplogistics.com;Carlisleleads@pfplogistics.com", "12/13/22", 8, 11, 6, 0, 15, 13, 'Workplace Violance', null, null, 'Testing SOD'],
            ["tday@pfplogistics.com;Carlisleleads@pfplogistics.com", "12/14/22", 8, 8, 5, 0, 8, 12, 'Lifting technique', null, null, '1 second test']
        ],
        columns: [
            { title: 'Email To', width: '400px', tooltip:'Email To', type:'dropdown', source:['tday@pfplogistics.com','Carlisleleads@pfplogistics.com','Icleveland@pfplogistics.com'], options:{ newOptions:true,autocomplete:true, multiple:true } },
            { title: 'Date of Summary', width: '200px', type: Calendar, tooltip:'Date of Summary' },
            { title: 'Local Loaders', width: '150px', tooltip:'Local Loaders' },
            { title: 'Local Unloaders', width: '150px', tooltip:'Local Unloaders' },
            { title: 'Local Scanners', width: '150px', tooltip:'Local Scanners' },
            { title: 'Associates in Training', width: '200px', tooltip:'Associates in Training' },
            { title: 'Outbounds', width: '100px', tooltip:'Outbounds' },
            { title: 'Inbounds', width: '100px', tooltip:'Inbounds' },
            { title: 'Daily Safety Topic', width: '200px',type:'dropdown', source:['forkilift safety','Stretching','Forklifts','Workplace Violance','Lifting technique'], options:{ newOptions:true,autocomplete:true, type:'picker' }, tooltip:'Daily Safety Topic' },
            { title: "Load Today's Roster", width: '200px', tooltip:"Load Today's Roster" },
            { title: 'Roster', width: '100px', tooltip:'Roster' },
            { title: 'Notes', width: '100px', tooltip:'Notes' }
        ],
        filters: true,
        worksheetName:"Start of Day"
        
    }]
}