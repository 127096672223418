import React, { useRef, useEffect, useState } from "react";
import jspreadsheet from "jspreadsheet";
import render from "@jspreadsheet/render";

import "/node_modules/jspreadsheet/dist/jspreadsheet.css";
import "/node_modules/jsuites/dist/jsuites.css";
import importer from '@jspreadsheet/importer';
import formula from "@jspreadsheet/formula-pro";
import parser from "@jspreadsheet/parser";
import validations from "@jspreadsheet/validations";
import search from '@jspreadsheet/search';
import jSuites from 'jsuites';
import Calender from '../Editors/Calander';
import Swal from "sweetalert2";

let download = function (e) {
  // The method should receive the spreadsheet DOM element
  jspreadsheet.render(e);
};

export default function Spreadsheet(props) {
  const jssRef = useRef(null);

  jspreadsheet.setLicense(
    "NWM4ZTg5YTk5YTE5MDExZmMxOTk1YWFiMjMwN2JlMTVkZTE0NjZiODlhYjdlNmI2YTE0OWI2ODMwZTZhNGFlYzk3M2M5ODY0NDliN2Q0YTQwM2QwNTUyYzA4MmU4YzQ3Y2I2ODU3YTU2MDJmMGU1NDBiZDU5Yzk2YmZiYzA0OGMsZXlKdVlXMWxJam9pVTJoMVlXbGlJaXdpWkdGMFpTSTZNVFkzTlRJNU5qQXdNQ3dpWkc5dFlXbHVJanBiSW5CbVlXeHZaMmx6ZEdsamN5MXFjM053Y21WaFpITm9aV1YwTG1Wd2FXdHpiMngxZEdsdmJpNXVaWFFpTENKc2IyTmhiR2h2YzNRaVhTd2ljR3hoYmlJNk1Dd2ljMk52Y0dVaU9sc2lkamNpTENKMk9DSXNJblk1SWwxOQ=="
  );
  jspreadsheet.setExtensions({ render, importer, parser, formula, validations, search   });

  useEffect(() => {
    // Create only once
    if (!jssRef.current.jspreadsheet) {
      //   jspreadsheet.setDictionary({
      //     Show: "Mostrar",
      //     Search: "Buscar",
      //     entries: " entradas",
      //     "Column name": "Nome da coluna",
      //     "Change column type": "Mudar tipo de coluna",
      //     "Insert a new column before": "Inserir uma coluna antes",
      //     "Insert a new column after": "Inserior uma coluna depois",
      //     "Delete selected columns": "Apagar colunas selecionadas",
      //     "Rename this column": "Renonear essa coluna",
      //     "Order ascending": "Ordenar ascendente",
      //     "Order descending": "Ordenar descendente",
      //     "Insert a new row before": "Inserir uma nova linha antes",
      //     "Insert a new row after": "Inserir uma nova linha depois",
      //     "Delete selected rows": "Apagar linhas selecionadas",
      //     "Edit comments": "Editar comentários",
      //     "Add comments": "Adicionar comentários",
      //     Comments: "Comentários",
      //     "Clear comments": "Apagar comentários",
      //     Copy: "Copiar",
      //     Paste: "Colar",
      //     "Save as": "Salvar como",
      //     About: "Sobre",
      //     "Are you sure?": "Tenha certeza:",
      //     "This action will destroy any existing merged cells. Are you sure?":
      //       "Essa ação irá desturir as celulas mescladas existentes. Tem certeza?",
      //     "Invalid merged properties": "Propriedades de mesclagem inválidas",
      //     "No cell selected": "Nenhuma celula selecionada",
      //     "Rename this worksheet": "Renomear essa planilha",
      //     "Delete this worksheet": "Apagar essa planilha",
      //     "It was not possible to rename worksheet due conflict name":
      //       "Não foi possível renomear essa planilha devido a um conflito de nomes",
      //     Cut: "Cortar",
      //     Hide: "Esconder",
      //     Unhide: "Mostrar",
      //     "Select All": "Selecionar tudo",
      //     Blanks: "Brancos",
      //     "Add current selection to filter": "Adicionar seleção atual ao filtro",
      //     "Rename this cell": "Renomear celula",
      //     // Calendar text
      //     Jan: "Jan",
      //     Feb: "Fev",
      //     Mar: "Mar",
      //     Apr: "Abr",
      //     May: "Mai",
      //     Jun: "Jun",
      //     Jul: "Jul",
      //     Aug: "Ago",
      //     Sep: "Set",
      //     Oct: "Out",
      //     Nov: "Nov",
      //     Dec: "Dez",
      //     January: "Janeiro",
      //     February: "Fevereiro",
      //     March: "Março",
      //     April: "Abril",
      //     May: "Maio",
      //     June: "Junho",
      //     July: "Julho",
      //     August: "Agosto",
      //     September: "Setembro",
      //     October: "Outubro",
      //     November: "Novembro",
      //     December: "Dezembro",
      //     Sunday: "Domingo",
      //     Monday: "Segunda",
      //     Tuesday: "Terca",
      //     Wednesday: "Quarta",
      //     Thursday: "Quinta",
      //     Friday: "Sexta",
      //     Saturday: "Sabado",
      //     Done: "Feito",
      //     Reset: "Apagar",
      //     Update: "Atualizar"
      //   });

      jspreadsheet(jssRef.current, {
        worksheets: props.worksheet,
        toolbar: 'extended',
        includeHeadersOnDownload:true,
        // Allow create a new tab button
        tabs: {
            allowEdit:true,
            allowCreate: true,
            allowChangePosition: true,
            animation: true,
            position: "top",
        },
        // Intercept the new worksheet and define the options for the new worksheet
        onbeforecreateworksheet: function() {
            var options = {
                minDimensions: [16,15],
            }
            return options;
        },
        // Run an event as soon the new worksheet is created
        onopenworksheet: function(element, instance, worksheetNumber) {
            console.log(element, instance, worksheetNumber);
        },
        license: 'NWM4ZTg5YTk5YTE5MDExZmMxOTk1YWFiMjMwN2JlMTVkZTE0NjZiODlhYjdlNmI2YTE0OWI2ODMwZTZhNGFlYzk3M2M5ODY0NDliN2Q0YTQwM2QwNTUyYzA4MmU4YzQ3Y2I2ODU3YTU2MDJmMGU1NDBiZDU5Yzk2YmZiYzA0OGMsZXlKdVlXMWxJam9pVTJoMVlXbGlJaXdpWkdGMFpTSTZNVFkzTlRJNU5qQXdNQ3dpWkc5dFlXbHVJanBiSW5CbVlXeHZaMmx6ZEdsamN5MXFjM053Y21WaFpITm9aV1YwTG1Wd2FXdHpiMngxZEdsdmJpNXVaWFFpTENKc2IyTmhiR2h2YzNRaVhTd2ljR3hoYmlJNk1Dd2ljMk52Y0dVaU9sc2lkamNpTENKMk9DSXNJblk1SWwxOQ==',
        contextMenu: function(o, x, y, e, items, section) {
          console.log(items)
          if (section == 'header') {
            // Add a line
            items.push({ type: 'line' });

            // Items to the header only
            items.push({                                          
                title: jSuites.translate('Set editor to this column'),
                submenu: [
                  {
                      title:'Calender',
                      onclick: function() {
                          let config = o.getConfig();
                          config.columns[x].type = Calender;
                          o.setConfig(config)
                      }
                    },
                    {
                      title:'Text',
                      onclick: function() {
                          let config = o.getConfig();
                          config.columns[x].type = 'text';
                          o.setConfig(config)
                      }
                    },
                    // {
                    //   title:'Dropdown',
                    //   onclick: function() {
                    //       console.log(o)  
                    //       showPop();
                    //       // o.setColumnOptions(null, null, Calender);
                    //       // type.type = Calender;
                    //       console.log(o.getConfig().columns[x]);
                    //       let config = o.getConfig();
                    //       config.columns[x].type = Calender;
                    //       console.log(config.columns[x].type)
                    //       o.setConfig(config)
                    //       console.log(o.getConfig());
                    //   }
                    // }
                  ]
            });
         }
        }
      });
    }
  }, null);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const showPop = ()=>{
    Swal.fire('Any fool can use a computer')
  }

  const addRow = function (e) {
    let activeWorksheet = jssRef.current.jspreadsheet[0].parent.getWorksheetActive() || 0;
    if(!rowsToAdd || rowsToAdd === 0)return;
    for(let i=0; i<rowsToAdd; i++){
        jssRef.current.jspreadsheet[activeWorksheet].insertRow();
    }
  };

  var [rowsToAdd, setRows] = useState(0);
  const varyRows = (e)=>{
    setRows(e.target.value)
  }

//   var load = function(e) {
//     // Parse XLSX file and create a new spreadsheet
//     jspreadsheet.parser({
//         file: e.target.files[0],
//         // It would be used to updated the formats only
//         locale: 'en-US',
//         onload: function(config) {
//             jspreadsheet(document.getElementById('spreadsheet'), config);
//         },
//         onerror: function(error) {
//             alert(error);
//         }
//     });
// }

  return (
    <div>
      <div ref={jssRef} />
      <br />
      <input
        type="number"
        value={rowsToAdd}
        onChange={varyRows}
        name="dynamicRow"
        className="jss_object"
        placeholder="How many rows do you need (upto 100)"
        maxLength={100}
        style={{marginRight:'5px'}}
      />
      <input
        type="button"
        value="Add Multiple Rows"
        onClick={addRow}
        style={{marginRight:'5px'}}
      />
      <input
        type="button"
        onClick={() => download(jssRef.current)}
        value="Export to EXCEL"
      />
           {/* <input type="file" name="file" onChange={(e) => load(e)} /> */}
    </div>
  );
}
